import { TagGameList } from '../pages/Minigame/List';

function AdminMinigameTagsRoute() {
  return <TagGameList />;
}

export const Component = AdminMinigameTagsRoute;

export function clientLoader() {
  return null;
}
